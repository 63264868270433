<template>
  <div class="item">
    <div class="position">
      <div class="name" @click="goDetail(item)">
        {{item.name|textFormat}}
      </div>
      <div class="money">
        <span v-if="item.salary_lowest!=0">{{item.salary_lowest|textFormat}}K-{{item.salary_highest|textFormat}}K</span>
        <span v-else>薪资面议</span>
      </div>
    </div>
    <div class="address">
      <div class="education">
<!--        {{item.address|textFormat}} | {{item.recruit_type}} | {{item.level_require}}-->
        <span>
          {{item.city_name_en|priorFormat(item.city_name_zh,LOCALE)}}
        </span>
        <span style="margin: 0 5px">|</span>
        <span>
          {{item.experience|experienceFormat(LOCALE)|textFormat}}
        </span>
        <span style="margin: 0 5px">|</span>
        <span>
          {{item.level_require|levelRequireFormat(LOCALE)|textFormat}}
        </span>
      </div>
      <div class="time">
        发布于 {{item.created_time|secondFormat('L')}}日
      </div>
    </div>
    <!-- <div class="btn-wrap">
      <div class="delivery">
        <submitResume
            :position_id="item.position_id"
            @successChange="successSubmitResume"></submitResume>
      </div>
      <div class="reservation">
        <orderTalk
            :in_companyId="item.company_id"
            :in_userId="item.person_in_charge"
            :position_id="item.position_id"
            @successChange="successChange"
        ></orderTalk>
      </div>
    </div> -->
  </div>
</template>

<script>
import submitResume from "~scr/components/button/submitResume";
import orderTalk from "~scr/components/button/orderTalk";
export default {
  name: "viewPostItem",
  components:{
    submitResume,
    orderTalk
  },
  props: {
    item: {
      type:Object,
      default:function(){
        return  {}
      }
    }
  },
  mounted() {
  },
  methods: {
    successSubmitResume(){
      this.$message({
        message: '投递成功',
        type: 'success',
        offset:100
      });
    },
    successChange(){
      this.$message({
        message: '预约成功',
        type: 'success',
        offset:100
      });
    },
    goDetail(item) {
      console.log(item)
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.position_id,
          }),
        },
      });
    },
  }
}
</script>

<style scoped lang="less">
.item {
  width: 100%;
  background: #F8F9FC;
  padding: 12px 16px;
  margin-bottom: 12px;

  .position {
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }

    .money {
      font-size: 14px;
      font-weight: 400;
      color: #FF2B0D;
    }
  }

  .address {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-top: 8px;
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .delivery {
      margin-right: 16px;
    }
  }
}
</style>
